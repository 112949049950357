@import "./ProductNumberPicker/style.scss";

li.LineItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid black;
  margin-bottom: 8px;

  &:first-child {
    border-top: 1px solid black;
  }

  > img {
    margin-right: 4px;
    cursor: pointer;
  }

  > label {
    margin-right: auto;
  }

  > p {
    font-family: monospace;
  }

  > .ProductNumberPicker {
    margin-right: 8px;
  }
}
