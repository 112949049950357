#modal-wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  figure {
    position: relative;
    cursor: default;
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    max-height: 80vh;
    max-width: 80vh;
    background: #f5f5f7;

    img {
      width: 100%;
      height: 100%;
    }

    figcaption {
      position: absolute;
      left: 8px;
      bottom: 8px;
    }
  }

  img.arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 25px;
    width: 40px;
    height: 60px;
    cursor: pointer;
    transition: transform ease-out 400ms;

    @media(max-width: 450px) {
      left: 10px;
    }

    &:hover {
      transform: translate(-4px, -50%);
    }

    &.forward {
      left: auto;
      right: 25px;

      @media(max-width: 450px) {
        right: 10px;
      }

      &:hover {
        transform: translate(4px, -50%);
      }
    }
  }
}
