#app > main > footer {
  // position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  // z-index: 1;
  background: #111;
  color: white;

  // @media (max-width: 900px) {
  //   padding: 0 30px;
  // }
  // @media (max-width: 400px) {
  //   padding: 0 20px;
  // }

  h4 {
    margin-top: 20px;
  }
  #footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;

    align-items: center;
    padding-top: 20px;
  }
  .footer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 55px;
      filter: invert(1);

      @media (max-width: 600px) {
        margin-right: auto;
      }
    }
  }
  #footer2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: "Cormorant Garamond", "Montserrat", serif;

    @media (max-width: 600px) {
      text-align: right;
    }

    p {
      font-family: "Cormorant Garamond", "Montserrat", serif;
      margin: 0;
    }
    img {
      height: 20px;
      width: auto;
      padding: 5px 2px;
    }
    a {
      color: white;
      font-family: "Cormorant Garamond", "Montserrat", serif;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
