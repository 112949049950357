#app > main > header {
  top: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  height: 160px;
  padding: 0 150px;

  @media (max-width: 900px) {
    height: 80px;
    padding: 0 30px;
  }
  @media (max-width: 400px) {
    height: 80px;
    padding: 0 20px;
  }

  > div {
    width: 100%;
  }

  .navi {
    color: black;
    display: flex;
    width: 100%;

    a {
      font-weight: 100;
      font-size: 15px;
      padding: 15px;

      @media (max-width: 500px) {
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(6) {
          display: none;
        }
      }

      &:nth-child(4) {
        margin-left: auto;
      }

      &:hover {
        text-decoration: underline;
        color: rgb(193, 193, 196);
      }
    }
    img {
      height: 20px;
    }
  }
}
