ul.shipping-radio {
  margin: 20px 0;
  li {
    position: relative;
    display: flex;
    align-items: center;

    > input {
      position: absolute;
      top: 22px;
      left: 24px;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }

    > label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      padding: 8px 8px 8px 48px;
      cursor: pointer;

      > span {
        float: right;
      }
    }
  }
}
