#about2 {
  > img {
    width: 100%;
  }

  b {
    font-weight: bold!important;
  }

  > div {
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    padding: 80px 120px;
    margin: auto;

    @media (max-width: 1200px) {
      padding: 80px 100px;
    }

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      padding: 80px 80px;
    }

    @media (max-width: 600px) {
      padding: 60px 20px;
      max-width: 400px;
      margin: auto;
    }

    > h2 {
      display: inline;
      font-size: 32px;
      margin-bottom: 20px;
    }

    > p {
      margin-bottom: 10px;
      line-height: 22px;
    }

    .block {
      display: inline;
      box-sizing: border-box;
      float: right;
      width: 480px;
      padding: 30px;
      background: #c4d6ff;
      margin: 0 0 20px 15px;

      @media (max-width: 900px) {
        order: 1;
        margin: 20px auto 0 auto;
        max-width: 100%;
      }

      h3 {
        margin-bottom: 20px;
        font-size: 20px;
      }
      p {
        margin-bottom: 16px;
        font-size: 13px;
        line-height: 140%;
      }
    }
  }
}
