@import "./About/style.scss";
@import "./Cart/style.scss";
@import "./products/style.scss";

#hero {
  position: relative;
  height: 100vh;
  background: transparent;
  overflow: hidden;

  h1.centred {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: #153250;
    white-space: nowrap;
  }

  .parallax__layer--base {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    perspective: 300px;
  }

  // Source: https://keithclark.co.uk/articles/pure-css-parallax-websites/
  .parallax__layer--back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-style: preserve-3d;
    overflow: hidden;

    @media (min-width: 901px) {
      transform: translate3d(0px, 0, -200px) scale(1.66667);
    }

    > img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  > .scroll-prompt {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    user-select: none;
    left: 50%;
    top: calc(var(--vhExplore, 1vh) * 100 - 40px);
    transform: translate(-50%, -100%);
    opacity: 1;

    &.hide {
      animation: fadeout normal forwards 700ms ease-out;
    }

    @keyframes fadeout {
      0% {
        opacity: 1;
      }
      99% {
        opacity: 0;
      }
      100% {
        display: none;
        opacity: 0;
      }
    }

    > img {
      width: 24px;
      margin-top: 2px;
      animation: alternate both bounce 700ms infinite ease-in-out;

      @keyframes bounce {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(6px);
        }
      }
    }
  }
}
