header.thankyou {
  position: static!important;
}
section.thankyou {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;

  width: 100%;
  max-width: 1200px;
  padding: 40px 120px;

  @media (max-width: 1200px) {
    padding: 40px 100px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 40px 20px;
  }

  @media (max-width: 600px) {
    padding: 0 0 20px;
  }


  > div {
    padding: 0 20px;
      h1 {
        font-size: 68px;
        margin-top: 60px;
        margin-bottom: 40px;

        @media (max-width: 900px) {
          font-size: 48px;
          margin-top: 0;
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 40px;
      }
  }

  > img {
    width: 300px;
    padding-left: 30px;
    margin: auto;
    @media (max-width: 900px) {
      padding-left: 0px;
    }
  }
}
