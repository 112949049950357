// old
$grey: #3a3937;
$black: #000000;

$white: #f6f6f9;

body.scrolling-disabled {
  overflow: hidden;
}

h1 {
  font-size: 68px;
  line-height: 90%;
  margin-bottom: 10px;
}
h2 {
  font-size: 34px;
}
h3 {
  font-size: 24px;
}
p {
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0;
}

#reed-information {
  section {
    margin-top: 30px;
  }
}

.gutters {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  @media (min-width: 400px) {
    padding: 40px;
  }
  @media (min-width: 768px) {
    padding: 60px;
  }
}

.left-right-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    > * {
      width: calc((100% / 2) - 20px);
    }
    > *:last-child {
      margin-left: 40px;
    }
  }
}


#app {
  position: relative;

  &.scrolling-disabled {
    overflow: hidden;

    > * {
      filter: blur(5px);
    }

    & > #modal-wrapper,
    & > aside
     {
      filter: none
    }
  }

  &.cart-active aside.cart {
    transform: translatex(0%);
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  // Source: https://keithclark.co.uk/articles/pure-css-parallax-websites/
  main.parallax {
    @media (min-width: 901px) {
      perspective: 300px;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

@import "./Header/style.scss";
@import "./Home/style.scss";
@import "./ReedInformation/style.scss";
@import "./Thankyou/style.scss";
@import "./Footer/style.scss";
