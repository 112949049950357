@import "./Modal/style.scss";
@import "./BuyButton/style.scss";

#student-reed,
#intermediate-reed,
#professional-reed {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: #fff;

  @media (max-width: 900px) {
    height: auto;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    padding: 80px 120px;

    @media (max-width: 1200px) {
      padding: 80px 100px;
    }

    @media (max-width: 900px) {
      flex-direction: column;
      padding: 80px 80px;
    }

    @media (max-width: 600px) {
      padding: 60px 0px;
    }

    div.product {
      max-width: 400px;
      padding: 0 20px;

      h2 {
        font-size: 32px;
        margin-bottom: 20px;
      }

      span.price {
        display: block;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        margin-top: 8px;
        box-sizing: border-box;
        text-align: left;
      }

      p {
        font-size: 16px;
        line-height: 22px;
      }

      > ul {
        padding: 12px 0px;

        > li {
          font-size: 16px;
          line-height: 22px;
          margin-top: 10px;
        }
      }
      .disclaimer {
        font-size: 12px;
      }
    }

    ul.product-images {
      @media (max-width: 900px) {
        transform: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 400px;
        padding: 0 20px;
        margin: 30px auto auto;
      }

      li {
        width: 180px;
        height: 100%;
        margin: 12px;
        cursor: pointer;

        @media (max-width: 900px) {
          width: 100%;
          max-width: calc((100% - 24px) / 3);
          margin: 0 0 0 12px;
          &:first-child {
            margin: 0;
          }
        }

        img {
          width: 100%;
          min-height: 100%;
        }
      }
    }
  }
}

#student-reed {
  height: calc(100vh - 48px);
  height: calc(var(--vh, 1vh) * 100 - 48px);

  @media (max-width: 900px) {
    height: auto;
  }

  > div {
    box-sizing: border-box;
    padding-bottom: calc(80px - 48px);

    @media (max-width: 900px) {
      padding-bottom: calc(80px - 40px);
    }

    @media (max-width: 600px) {
      padding-bottom: calc(60px - 40px);
    }
  }
}

#intermediate-reed,
#professional-reed {
  border-top: 10px solid #f5f5f7;
}

section canvas#three {
  width: 300px;
  height: 500px;
}

section iframe {
  width: calc(100% - 480px);
  max-width: 500px;

  @media (max-width: 900px) {
    position: static;
    right: 0px;
    top: 0;
    transform: translateY(0);
    width: 100%;
    padding: 20px 0;
    max-width: none;
  }
}
