@import "./LineItem/style.scss";
@import "./ShippingRadio/style.scss";
@import "./CartIcon/style.scss";

aside.cart {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 420px;
  height: calc(var(--vhCart, 1vh) * 100);
  background: #f5f5f7;
  z-index: 1;

  transition: transform ease 200ms;
  transform: translatex(100%);

  @media (max-width: 500px) {
    width: 100vw;
  }

  .disclaimer {
    font-size: 12px;
  }

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    padding: 8px 16px;

    @media (max-width: 1000px) {
      height: 48px;
      padding: 8px 4px 8px 16px;
    }

    @media (max-width: 500px) {
      height: 40px;
      padding: 8px 0 8px 12px;
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
    }

    button {
      cursor: pointer;
      background: none;
      border: none;
      padding: 8px;
      margin-left: auto;
      user-select: none;

      > img {
        width: 24px;
        height: 24px;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    padding: 8px 16px;

    > h2 {
      padding: 16px 0;
    }

    > ul.lineItem-list {
      margin-bottom: auto;
    }

    > p {
      line-height: 32px;
      padding: 8px;
    }

    > button {
      font-size: 16px;
      font-weight: bold;
      user-select: none;
      padding: 0 36px;
      min-width: 242px;
      line-height: 39px;
      margin-top: 8px;
      background: #222323;
      border: none;
      border-radius: 5px;
      color: white;

      &:hover {
        background: #595a5a;
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
