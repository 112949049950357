button.buy-button {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  padding: 0 36px;
  min-width: 242px;
  line-height: 39px;
  margin-top: 8px;
  background: #222323;
  border: none;
  border-radius: 5px;
  color: white;
  user-select: none;

  @media (max-width: 400px) {
    width: 100%;
  }

  svg {
    position: absolute;
    right: 14px;
    height: 39px;
    fill: white;
    transition: transform ease 400ms;
  }

  &:hover {
    background: #595a5a;

    svg {
      transform: translatex(4px);
    }
  }
}
