.ProductNumberPicker {
  display: flex;
  border: 1px solid $grey;

  button,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $white;
    border: none;
    line-height: 32px;
    font-size: 18px;
    font-family: monospace;
    color: $black;
    user-select: none;

    &:disabled {
      color: rgba($black, 0.5);
    }
  }
}
